import React from 'react'
import { graphql, Link } from 'gatsby'

//Katex
import renderMathInElement from 'katex/dist/contrib/auto-render'
import 'katex/dist/katex.min.css'

// components
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import SEO from '../../components/SEO/seo'
import Layout from '../../components/Layout/layout'
import Section from '../../components/Section/section'
import GetStarted from  '../../components/GetStarted/getStarted'
import Article from '../../components/Blog/blogArticle'

//constants
import { NOTES_SLUG } from '../../utils/constants'

class BlogPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchText: '',
            filterValue: ''
        }
    }

    _renderLatex = () => {
        renderMathInElement(this.katexField)
    }

    componentDidMount() {
        this._renderLatex()
    }

    componentDidUpdate() {
        this._renderLatex()
    }

    render({ data }){
        const notes = data.allContentfulNotes.nodes
        const categories = data.allContentfulTopicCategory.nodes
        return <>
            <SEO title='Exam Resources'/>
            <Layout className={'qs-article-list'}>
                <div ref={ref => this.katexField = ref}>
                    <Section background={'bg-cream'} animation={false}>
                        <Col lg={12}>
                            <h1 className='mb-2 mt-4 mt-lg-0 text-left qs-article-list__title'>{`Exam Resources`}</h1>
                            <Row>
                                <Col xs={7} lg={9} className='form-group'>
                                    <label>Search</label>
                                    <input type='search' className='form-control' onKeyUp={e => this.setState({searchText: e.currentTarget.value})}/>
                                </Col>
                                <Col xs={5} lg={3}  className='form-group'>  
                                    <label>Filter by</label>                      
                                    <select type='select' className='form-control' value={this.state.filterValue} onChange={e => this.setState({filterValue: e.currentTarget.value})}>
                                        <option unselectable hidden></option>
                                        <option>All</option>
                                        {
                                            categories.map((node) => (
                                                <option key={node.id} value={node.topicCategory}>{node.topicCategory}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </Row>
                            <hr/>
                            <Row className='d-flex align-items-center mb-3'>
                                <Col className='col text-small d-flex'>
                                    <div>{notes.length} notes returned</div>
                                    <div className='ml-2 d-flex align-items-center'>
                                        {!!this.state.filterValue && <>
                                            <span>Filtered by:</span>
                                            <span className='badge badge-primary py-1 px-2 ml-2' onClick={() => this.setState({filterValue: ''})}>
                                                {this.state.filterValue} <span className='text-white pl-2'>X</span>
                                            </span></>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    notes.filter(value => value.topicCategory.topicCategory.includes(this.state.filterValue) 
                                && (value.title.toLowerCase().includes(this.state.searchText.toLowerCase()) || value.description.description.toLowerCase().includes(this.state.searchText.toLowerCase()))
                                    ).map((node) => (
                                        <Col as='div' className='col-12 col-md-6 col-lg-6 col-xl-4 mb-3' key={node.id}>
                                            <Link to={`/${NOTES_SLUG}/${node.slug}`} className='qs-article-item'>
                                                <Article 
                                                    thumbnail={node.topic.topicImageSource}
                                                    title={node.title}
                                                    createdDate={node.createdAt}
                                                    topic={node.topic}
                                                    topicCategory={node.topicCategory}
                                                    description={node.description.description}
                                                    slug={NOTES_SLUG}
                                                />                                      
                                            </Link>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Section>
                </div>
                <GetStarted
                    background='bg-light-yellow'
                    buttonVariant='primary'
                    secondaryButton={true}
                    secondaryButtonText={`Just answer questions and you will get an A - A* - guaranteed`}
                />
            </Layout>
        </>
    }
}

export default BlogPage

export const query = graphql`
query getLessonNote {
  allContentfulNotes {
      nodes {
        slug
        id
        title
        description {
          description
        }
        topic {
          topicImageSource
          topicName
          contentful_id
        }
        topicCategory{
          topicCategory
          contentful_id
        }
        metadata {
          tags {
            name
          }
        }
        updatedAt(formatString: "MMMM D, YYYY")
        createdAt(formatString: "MMMM D, YYYY")
      }
    }
  allContentfulTopicCategory {
    nodes {
      id
      topicCategory
    }
    
  }
}
`
